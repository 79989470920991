//========================================================================
//  $RESET
//========================================================================
@import "normalize";

html * {
    margin:  0;
    padding: 0;
}

fieldset {
    border: 0;
}

img {
    vertical-align: middle;
}

textarea {
    resize: vertical;
}

//  $BREAKPOINT SYSTEM
//------------------------------------------------------------------------

@import "include-media";

//  $PERFORMANCE
//------------------------------------------------------------------------

.disable-hover {
    pointer-events: none;
}

//  $BOX-SIZING
//------------------------------------------------------------------------

html{
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

//  $FONT SMOOTH
//------------------------------------------------------------------------

html,
body,
button {
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing:  antialiased;
    font-smoothing:          antialiased;
}


//  $SPRITE
//------------------------------------------------------------------------

@import "tooltips";
@import "sprite";
.icon {
    display: inline-block !important; // css-sprite issue
}

//========================================================================
//  $TYPOGRAPHY
//========================================================================

@import url(http://fonts.googleapis.com/css?family=Bitter:400,700);
@import "fonts";

@function rem($x) {
    @return ($x / 16)+rem;
}

// Font variables:
$base-font-size: rem(16);
$base-line-height: 1.5;
$f-first: "Bitter", "Arial", sans-serif;
$f-second: "Segoe UI", sans-serif;

html, body {
  font-size:   $base-font-size;
  line-height: $base-line-height;
  font-family: $f-first;
  height:      100%;
  min-height:  100%;
}

h1, h2, h3, h4, h5, h6{
  margin-top: 1rem;
  line-height: 1.1;
}

//========================================================================
//  $GRID SYSTEM
//========================================================================

.grid {
  $gutter: 20px;
  display: flex;
  &__cell {
    flex: 1;

    /* Alignment per cell */
    &--top { align-self: flex-start; }
    &--bottom { align-self: flex-end; }
    &--center { align-self: center; }
    &--baseline { align-self: baseline; }
    &--stretch { align-self: stretch; }
  }

  /* Alignment per row */
  &--top { align-items: flex-start; }
  &--bottom { align-items: flex-end; }
  &--center { align-items: center; }
  &--baseline { align-items: baseline; }
  &--stretch { align-items: stretch; }
  &--baseline { align-items: baseline; }

  &--start { justify-content: flex-start; }
  &--end { justify-content: flex-end; }
  &--j-center { justify-content: center; }
  &--between { justify-content: space-between; }
  &--around { justify-content: space-around; }


  /* Base classes for all media */
  &--fit > .grid__cell { flex: 1; }
  &--full > .grid__cell { flex: 0 0 100%; }
  &--1of2 > .grid__cell { flex: 0 0 50%; }
  &--1of3 > .grid__cell { flex: 0 0 33.3333%; }
  &--1of4 > .grid__cell { flex: 0 0 25%; }

  /* With gutters */
  &__gutters {
    margin-left: -#{$gutter};
    & > .grid__cell {
      padding-left: $gutter;
    }
  }
}

//  $BUTTONS
//------------------------------------------------------------------------

.btn {
  cursor: pointer;
  display: block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  &:hover { text-decoration: none; }
  &--small{
    width: 120px;
    font-size: 14px;
    font-weight: 700;
    line-height: 35px;
    color:  #fefefe;
  }
  &--large{
    width: 200px;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    color:  #fefefe;
    background:  #00c513 url(../img/large-btn.png) repeat-x !important;
  }
  &--green {
      box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
      outline: 1px solid !important;
      outline-color: rgba(0, 197, 19, 1) !important;
      outline-offset: 0px;
      border-radius: 0px;
      background:  #00c513 url(../img/button-bg.png) repeat-x;
      //background: linear-gradient(0deg, rgb(17, 139, 17) 0%, rgb(11, 197, 29) 100%);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.45);
      text-shadow: none;
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      overflow: hidden;
      text-shadow: 1px 1px 2px #427388;
      span {
          z-index: 20;
        }
      &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 10;
      }
      &:active{
        &:after {
          left: 120%;
          transition: all 50ms cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
      &:hover {
        border: 1px solid;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
        outline-color: rgba(255, 255, 255, 0) !important;
        outline-offset: 15px;
        text-shadow: 1px 1px 2px #427388;
      }
  }
}

//========================================================================
//  $ MAIN STYLES
//========================================================================

.wrapper{

}

ul, nav{list-style: none;}

$color1: #0a1c50;
$color1: #0a1c50;
$color1: #0a1c50;

$width-max: 986px;

.wrap{
  max-width: $width-max;
  margin: 0 auto;
  width: 100%;
}

.hidden{display: none;}


//  $HEADER
//------------------------------------------------------------------------
.section-1{
  width: 100%;
  height: 95px;
  background: $color1;
  background:transparent url('../img/top-bg.png') repeat-x;
  padding-top: 14px;
}
.header{
  height: 100%;
  @extend .wrap;
  position: relative;
  @include media("<=980px"){
  }
  &__menu-btn{
    background: url(../img/menu-btn.png);
    width: 41px;
    height: 41px;
    position: absolute;
    right:12px;
    top: 22px;
    cursor: pointer;
    @include media(">=980px"){
      display: none;
    }
  }
  &__logo{
    background:transparent url('../img/logo.png') center no-repeat;
    width: 100%;
    height: 100%;
    display: block;
    @include media("<=630px"){
      background-size: contain;
    }
    //max-width: 866px;
  }
}


//  $NAVIGATION
//------------------------------------------------------------------------
.section-2{}
.nav{
  &-s{display: flex !important}
  @extend .wrap;
  background-color:  #f5f7f9;
  @include media(">980px"){
    display: flex !important;
  }
  @include media("<=980px"){
    display: none;
    position: absolute;
    width: 200px;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: 100;
    right: 0;

  }
  a{
    color:  #444444;
    font-size: 0.875rem;
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 20px;
    transition: linear .2s;
    @include media("<=980px"){
        line-height: 10px;
        padding: 19px 20px 13px;
    }
    &:hover{
      background:  #00247d url(../img/a-bg.jpg);
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.45);
      color: #fff
    }
  }
}


//  $TEXT MAIN
//------------------------------------------------------------------------
.section-3{ }
.top-text{
  margin-top: 28px;
  @extend .wrap;
  @include media("<=980px"){
    flex-direction: column;
  }
  &__left h1{
    padding-top: 17px;
    color:  #444444;
    font-size: 2rem;
    width: 413px;
    min-height: 180px;
    border-right: 1px solid #e0e0e0;
    padding-right: 37px;
    line-height: 1.125;
    @include media("<=980px"){
    margin: auto;
    border-right: 0;
    width: 80%;
    padding-right: 0;
  }

  }
  &__right{
    padding-top: 17px;
    width: 550px;
    padding-left: 40px;
    color:  #444444;
    font-size: 0.938rem;
    line-height: 1.225;
    @include media("<=980px"){
    margin: auto;
    width: 80%;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 40px;
    padding-left: 0;
  }
  }
}

.top-box{
  position: relative;
  padding-bottom: 38px;
  margin-top: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #e0e0e0;
  @extend .wrap;
  @include media("<=980px"){
    display: block;
    padding-bottom: 120px;
  }
  @include media("<=550px"){
    padding-bottom: 0;
  }
  &__item-1{
    width: 300px;
    @include media("<=980px"){
      margin: 0 auto;
      text-align: center;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 32px;
    }
    &__h1{
      color:  #444;
      font-size: 2rem;
      margin-top: 1.2rem;
      margin-bottom: 10px;
      font-weight: 400;
    }
    &__p{
      color:  #cf142b;
      font-size: 0.875rem;
    }
  }
  &__item-2{
    width: 120px;
    height: 101px;
    margin-left: -50px;
    @include media("<=980px"){
      width: 100%;
      height: 300px;
      margin-left: 0;
    }
    &__img-small{
      height: 100%;
      width: 100%;
      flex-direction: column;
      @include media("<=980px"){
        display: none;
      }
      a{
        margin: auto;
      }
      img{
        margin: auto;
        width: 119px;
        height: 48px;
      }
      .btn{
        height: 35px;
      }
    }
    &__casino{
      position: absolute;
      right: 0;
      top: 165px;
      @include media("<=980px"){
        position: initial;
        margin: 0 auto;
        max-width: 300px;
        margin-top: 20px;
      }
      &__logo{
        img{
          width: 301px;
          height: 128px;
        }
      }
      &__rating{
        .btn{
          width: 280px;
        }
        .grid{
          margin-top: 16px;
        }
        & > .grid .list__row__rating__star-y,
        & > .grid .list__row__rating__star-w{
          width: 31px;
          height: 31px;
        }
        .list__row__rating__text a{
          font-family: $f-second;
          color:  #082b81;
          font-size: 1.083rem;
          font-weight: 600;
        }
      }
      &__visit{
        a{
          width: 280px;
          margin-top: 24px;
        }
      }
    }
  }
  &__item-3{
    background-color:  #f7f7f7;
    width: 224px;
    height: 101px;
    text-align: center;
    text-decoration: none;
    @include media("<=980px"){
      position: absolute;
      left: 50%;
      margin-left: -234px;
    }
    @include media("<=550px"){
      position: initial;
      left: 0;
      margin: 20px auto;
    }
    &__h{
      color:  #444;
      font-size: 1.313rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 3rem;
    }
    span{
      font-family: $f-second;
      color:  #cf142b;
      font-size: 1rem;
      font-weight: 600;
    }
    p{
      color:  #444444;
      font-family: $f-second;
      font-size: 1rem;
      font-weight: 600;
    }
  }
  &__item-4{
    background-color:  #f7f7f7;
    width: 224px;
    height: 101px;
    text-align: center;
    text-decoration: none;
    @include media("<=980px"){
      position: absolute;
      left: 50%;
      margin-left: 10px;
    }
    @include media("<=550px"){
      position: initial;
      left: 0;
      margin: 20px auto;
    }
    p{
      font-family: $f-second;
      color:  #002fa9;
      font-size: 2.626rem;
      font-weight: 700;
      text-align: center;
      line-height: .8;
    }
  }
}

//  $SORT LIST
//------------------------------------------------------------------------
.section-4{}
.list{
  padding-bottom: 75px;
  @extend .wrap;
  & > .grid{
    @include media("<=980px"){
        justify-content: center;
      }
  }
  &__h{
    color:  #cf142b;
    font-size: 0.875rem;
    @include media("<=550px"){
        text-align: center;
        font-size: 1rem;
        padding-top: 25px;
      }
    @include media("<=980px", ">550px"){
        text-align: center;
        font-size: 2rem;
        padding-top: 25px;
      }
    &--border-bottom{
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 3px;
      margin-bottom: 14px;
      @include media("<=980px"){
        border-bottom: none;
      }
    }
  }
  &__sorted{
    border-radius: 2px;
    border: 1px solid rgb(224, 224, 224);
    background: transparent url('../img/list-span-bg.png') right no-repeat;
    width: 118px;
    height: 35px;
    margin-left: 10px;
    cursor: pointer;
    &>span{padding-left: 10px;}
    &__span{
      font-family: $f-second;
      color:  #777;
      font-size: 0.75rem;
      line-height: 35px;
      float: left;
      padding-left: 10px;
    }
    &__items{

      background: #fff;
      z-index: 62;
      position: relative;

    }
    &__item{
      font-family: $f-second;
      color:  #444;
      font-size: 0.75rem;
      line-height: 35px;
      cursor: pointer;
      background-color: #fff;
      padding-left: 10px;
      &:hover{
        background-color: #f7f7f7;
      }
    }
  }

  &__row{
    @extend .grid;
    @extend .grid--between;
    position: relative;
    border-bottom: 1px solid rgb(224, 224, 224);
    @include media("<=980px"){
      flex-wrap: wrap;
      max-width: 300px;
      margin: 30px auto;
      padding-bottom: 25px;
      height: 330px;
    }
    & > div:nth-child(2n+1){
        background-color: #f7f7f7;
        @include media("<=980px"){
          background: none;
        }
      }
    &__header{
      color:  #444;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 50px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #cf142b;
      margin-top: 14px;
      text-align: center;
      @include media("<=980px"){
        display: none;
      }
    }
    &__inner{
      height: 57px;
      margin-top: 15px;
      margin-bottom: 10px;
      @include media("<=980px"){
        margin: 0;
        height: auto;
      }
    }
    & .list__row__inner{
      font-family: $f-second;
      color:  #444444;
      font-size: 1.75rem;
      line-height: 57px;
      text-align: center;
      @include media("<=980px"){
        line-height: 30px;

      }
    }
    &__rank {
      &__span{
        @include media("<=980px"){
          font-size: 4.5rem ;
          margin-left: 0px;
          margin-top: 20px;
          position: absolute;
        }
      }
      width: 77px;
    }
    &__casino{
      width: 153px;
      @include media("<=980px"){
        width: 74%;
        position: absolute;
        right: 0;
      }
      a{
        margin: auto;
        height: 48px;
      }
      img{
        margin: auto;
        width: 119px;
        height: 48px;
        vertical-align: baseline;
        @include media("<=980px"){
          margin: 10PX 20PX 10PX 50PX;
          width: 159px;
          height: 68px;
        }
      }
    }
    &__bonus{
      width: 207px;
      @include media("<=980px"){
          margin-left: 100px;
          margin-top: -8px;
        }
      .box{
        margin:auto;
        text-align: center;
        line-height: 20px;
      }
      span{
        font-family: $f-second;
        color:  #cf142b;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.025rem;
        line-height: 1.25;
        text-align: center;
        @include media("<=980px"){
          font-size: 1rem;
          line-height: 1.25;
        }
      }
      p{
        font-family: $f-second;
        color:  #444444;
        font-size: 1rem;
        font-weight: 600;
        @include media("<=980px"){
          font-size: 1rem;
          line-height: 1.15;
        }
      }
    }
    &__features{
      width: 163px;
      @include media("<=980px"){
        position: absolute;
        bottom: 12px;
        right: 70px;
      }

      &__diamond{
        background:transparent url('../img/diamond.png') center no-repeat;
        width: 24px;
        height: 21px;
        background-size: contain;

      }
      &__headphones{
        background:transparent url('../img/headphones.png') center no-repeat;
        width: 24px;
        height: 21px;
        background-size: contain;

      }
      &__phone{
        background:transparent url('../img/phone.png') center no-repeat;
        width: 24px;
        height: 21px;
        background-size: contain;

      }
      &__download{
        background:transparent url('../img/download.png') center no-repeat;
        width: 24px;
        height: 21px;
        background-size: contain;

      }
      &__tv{
        background:transparent url('../img/tv.png') center no-repeat;
        width: 24px;
        height: 21px;
        background-size: contain;

      }
    }
    &__rating{
      width: 164px;
      @include media("<=980px"){
          position: absolute;
          right: 20px;
          top: 140px;
        }
      .grid{width: 110px; margin:  auto; padding-top: 4px;
          @include media("<=980px"){
            width: 160px;
          }
      }
      &__star-y{
        background:transparent url('../img/star-y.png') center no-repeat;
        width: 18px;
        height: 18px;
        background-size: contain;
        @include media("<=980px"){
          width: 48px;
          height: 48px;
        }
      }
      &__star-w{
        background:transparent url('../img/star-w.png') center no-repeat;
        width: 18px;
        height: 18px;
        background-size: contain;
        @include media("<=980px"){
          width: 48px;
          height: 48px;
        }
      }

      &__text{
          @include media("<=980px"){
          line-height: .3;
        }
        line-height: 20px;
        a{
          font-family: $f-second ;
          color: #082b81;
          font-size: 0.813rem;
          font-weight: 600;
          line-height: 1.539;
          text-decoration: underline;
          @include media("<=980px"){
            font-size: 1rem;
          }
        }
        text-align: center;
      }
    }
    &__payout{
      width: 86px;
      .list__row__inner{
        font-family: $f-second;
        color:  #444;
        font-size: 1.75rem;
        line-height: 57px;
        text-align: center;
        @include media("<=980px"){
          border-radius: 50%;
          background-color:  #ebebeb;
          width: 100px;
          height: 100px;
          line-height: 100px;
          position: absolute;
          top:100px;
          left: 5px;
        }
      }
    }
    &__visit{
      width: 144px;
      @include media("<=980px"){
        position: absolute;
        top: 230px;
        right: 50px;
        width: 200px;
      }
      & .list__row__inner .btn--small{
        @include media("<=980px"){
          width: 200px;
          font-size: 20px;
          font-weight: 700;
          line-height: 50px;
          color:  #fefefe;
          background:  #00c513 url(../img/large-btn.png) repeat-x;
          }

      }
    }
  }
  #Container .mix{
    display: none;
    @include media("<=980px", '>880px'){
      float: left;
      margin: 10px 7%;
    }
    @include media("<=880px", '>780px'){
      float: left;
      margin: 10px 5%;
    }
    @include media("<=780px", '>760px'){
      float: left;
      margin: 10px 40px;
    }

  }
  .rank-1{
    @include media("<=980px"){
        background-color: #f9f1a9;
      }
    .list__row__rank{
      @include media("<=980px"){
          width: 102px;
          height: 115px;
        }
    }
    .list__row__bonus {
      @include media("<=980px"){
        margin-left: 100px;
        margin-top: -80px;}
    }
    .list__row__rank:before{
      content: ' ';
      position: absolute;
      background:transparent url('../img/1.png') center no-repeat;
      width: 77px;
      background-size: contain;
      height: 82px;
      z-index: 50;
      left: -4px;
      @include media("<=980px"){
        background-size: contain;
        width: 107px;
        height: 119px;
        top: -5px;
        left:-2px;
      }
    }
    .list__row__inner{
      background-color: #f9f1a9;
      @include media("<=980px"){

      }
    }
    .list__row__payout .list__row__inner{
      @include media("<=980px"){
        background-color: #fff;
      }
    }
  }
  .rank-2{
      @include media("<=980px"){
        background-color: #edeeee;
      }
      .list__row__rank{
        @include media("<=980px"){
            width: 102px;
            height: 115px;
          }
      }
      .list__row__bonus {
        @include media("<=980px"){
          margin-left: 100px;
          margin-top: -80px;}
      }
    .list__row__rank:before{
      content: ' ';
      position: absolute;
      background:transparent url('../img/2.png') center no-repeat;
      width: 77px;
      background-size: contain;
      height: 82px;
      z-index: 50;
      left: -4px;
      @include media("<=980px"){
        background-size: contain;
        width: 107px;
        height: 119px;
        top: -5px;
        left:-2px;
      }
    }
    .list__row__inner{
      background-color: #edeeee;
      @include media("<=980px"){
        background: none;
      }
    }
    .list__row__payout .list__row__inner{
      @include media("<=980px"){
        background-color: #fff;
      }
    }
  }
  .rank-3{
    @include media("<=980px"){
        background-color: #f7e0bc;
      }
      .list__row__rank{
        @include media("<=980px"){
            width: 102px;
            height: 115px;
          }
      }
    .list__row__bonus {
      @include media("<=980px"){
        margin-left: 100px;
        margin-top: -80px;}
    }
    .list__row__rank:before{
      content: ' ';
      position: absolute;
      background:transparent url('../img/3.png') center no-repeat;
      width: 77px;
      background-size: contain;
      height: 82px;
      z-index: 50;
      left: -4px;
      @include media("<=980px"){
        background-size: contain;
        width: 107px;
        height: 119px;
        top: -5px;
        left:-2px;
      }
    }
   .list__row__inner{
      background-color: #f7e0bc;
      @include media("<=980px"){
        background: none;
      }
    }
    .list__row__payout .list__row__inner{
      @include media("<=980px"){
        background-color: #fff;
      }
    }
  }
}



/* Icon */

.icon {
  background: CornflowerBlue;
  position: relative;
  overflow: hidden;
}

/* "shine" element */
/* Could be a pseudo element but they lack support for CSS transitions in some browsers */

.icon .icon-effect {
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  z-index: 111111;
  opacity: 0;

  background: rgba(255, 255, 255, 0.2);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.5) 90%,
    rgba(255, 255, 255, 0.0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.5) 90%,
    rgba(255, 255, 255, 0.0) 100%
  );
  background: -webkit-gradient(
    linear, left top, right top,
    color-stop(0%  ,rgba(255, 255, 255, 0.2)),
    color-stop(75% ,rgba(255, 255, 255, 0.2)),
    color-stop(90% ,rgba(255, 255, 255, 0.8)),
    color-stop(100%,rgba(255, 255, 255, 0.0))
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.5) 90%,
    rgba(255, 255, 255, 0.0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.5) 90%,6
    rgba(255, 255, 255, 0.0) 100%
  );
  background: linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.5) 90%,
    rgba(255, 255, 255, 0.0) 100%
  );

  -webkit-transform: rotate(30deg);
     -moz-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
       -o-transform: rotate(30deg);
          transform: rotate(30deg);

  -webkit-transition-property: left, top, opacity;
     -moz-transition-property: left, top, opacity;
      -ms-transition-property: left, top, opacity;
       -o-transition-property: left, top, opacity;
          transition-property: left, top, opacity;
  -webkit-transition-duration: 0.5s, 0.5s, 0.1s;
     -moz-transition-duration: 0.5s, 0.5s, 0.1s;
      -ms-transition-duration: 0.5s, 0.5s, 0.1s;
       -o-transition-duration: 0.5s, 0.5s, 0.1s;
          transition-duration: 0.5s, 0.5s, 0.1s;
  -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
      -ms-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}

/* Hover state - trigger effect */

.mix:hover  .icon-effect {
  opacity: 1;
  top: -30%;
  left: -30%;
}

/* Active state */

.icon:active .icon-effect {
  opacity: 0;
}

//  $MAIN TEXT
//------------------------------------------------------------------------
.section-5{
  padding-bottom: 30px;
  .grid{
    @extend .wrap;
    @include media("<=980px"){
      flex-direction: column;
    }
  }
}

.main{
  width: 70%;
  @include media("<=980px"){
    margin: auto;
    width: 80%;
  }
  &__h{
    color:  #00247d;
    font-size: 1.313rem;
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    margin-bottom: 29px;
    &--casino{
      border-bottom: none;
    }
    @include media("<=980px"){
      text-align: center;
    }

  }
  &__content{
    color:  #444;
    font-size: 0.96rem;
    font-weight: 400;
    text-align: justify;
    line-height: 1.54;
    padding-right: 50px;
    &--casino{
      &__p{
        margin-top: 40px;
      }
      &__img{
        vertical-align: baseline;
        margin: 0 20px 5px 0;
      }
      .grid{
        margin-top: 60px;
        height: 70px;
      }
      .btn{
        width: 280px;
        margin: auto;
      }
    }
    @include media("<=980px"){
      padding-right: 0;
    }
  }
}
.aside{
  &__game-box{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &__item{
      margin-top:10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      &__img{
        width: 39%;
      }
      &__text{
        width: 59%;
        &__h{
          color: #3E89C7;
          font-size: 13px;
          font-weight: bold;
          @include media(">980px"){
            font-size: 9px;
          }
        }
        &__p{
          font-size: 11px;
          margin: 5px;
          @include media(">980px"){
            font-size: 9px;
            margin: 1px;
          }
        }
        &__find{
          color: #78B0DC;
          font-size: 9px;
          border: 1px solid #E6E6E6;
          background-color: #F0F0F0;
          padding: 3px;
          border-radius: 3px;
          text-decoration: none;
          @include media(">980px"){
            text-align: left;
            margin-top: 10px;
          }
        }
        &__date{
          font-size: 10px;
          color: #969696;
          text-align: right;
          margin-top: -15px;
          @include media(">980px"){

          }
          @include media("<=500px"){
            text-align: left;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &__top-box{
    &__item:last-child{border-bottom: none;}
    &__item{
      width: 290px;
      height: 75px;
      margin: 0 auto;
      border-bottom: 1px solid #e0e0e0;
      margin-top: 15px;
      &__img{
            margin-right: 24px;
      }
      &__rating{
        p{
          font-family: $f-second;
          color:  #2f2f2f;
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
      .list__row__rating__text a:first-child{
        font-family: $f-second;
        color:  #c21515;
        font-size: 0.8rem;
        font-weight: 600;
        padding-right: 3px;
      }
      .list__row__rating__text a:last-child {
        font-family: $f-second;
        color:   #2445c3;
        font-size: 0.8rem;
        font-weight: 600;
        padding-left: 3px;
      }
      .list__row__rating__text{
        color: #e0e0e0;
      }
    }
  }
  &__list{
    .list__row__rating__star-w,
    .list__row__rating__star-y{
      width: 18px;
      height: 18px;
    }
    .grid{
      flex-direction: row;
    }
  }
  &__casino{
    padding-top: 310px;
    width: 35%;
    @include media("<=980px"){
      padding-top: 0;
    }
  }
  width: 30%;
  @include media("<=980px"){
    margin: auto;
    width: 80%;
  }
  &__h{
    color:  #00247d;
    font-size: 1.313rem;
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    margin-bottom: 29px;
    @include media("<=980px"){
      text-align: center;
      margin-top: 50px;
    }
  }
  &__content{
    img{
      width: 100%;
      max-width: 400px;
      margin: auto;
      display: block;
    }

  }
}



//  $FOOTER
//------------------------------------------------------------------------
.section-6{
  background: url(../img/footer-bg.jpg);
  height: 238px;
  @include media("<=980px"){
    height: 138px;
  }
}
.footer{
  @extend .wrap;
  &__h{
    color:  #cf142b;
    font-size: 1.125rem;
    text-align: center;
    padding-top: 50px;
    position: relative;
    margin-bottom: 51px;
    @include media("<=980px"){
      padding-top: 10px;
    }
    &:after{
      content: '';
      position: absolute;
      width: 220px;
      height: 0px;
      border-bottom: 1px solid #e0e0e0;
      bottom: -10px;
      left: 50%;
      margin-left: -110px;
      @include media("<=980px"){
        width: 120px;
        margin-left: -60px;
      }
    }
  }
  &__nav{
    @include media("<=980px"){
      display: none;
    }
    &__a{
      font-family: $f-second;
      color:  #00247d;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.019rem;
      transition: linear .5s;
      text-decoration: none;
      &:hover{
        color:  #cf142b;
      }
    }
  }
}


//  $FOOTER COPYRIGHT
//------------------------------------------------------------------------
.section-7{
  background-color:  #f9fbfc;
  height: 48px;
  margin-top: -66px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}
.bottom{
  @extend .wrap;
  &__copy{
    padding-left: 20px;
    a,p{
      font-family: $f-second;
      color:  #999;
      font-size: 0.688rem;
      letter-spacing: 0.017rem;
      line-height: 48px;
      text-align: left;
    }
  }
  &__social{
    float: right;
    margin-top: -37px;
    padding-right: 20px;
    a{
      border-radius: 50%;
      background-color:  #b1b1b1;
      width: 21px;
      margin-left: 8px;
      height: 21px;
      color: #fff;
      font-size: 10px;
      display: inline-block;
      line-height: 23px;
      text-align: center;
      text-decoration: none;
      transition: linear .3s;
      &:hover{
        color: #b1b1b1;
        background: #fff;
      }
    }
  }
}

.mix:hover .anim{
  transform: scale(1.1);
}
.anim {
  transition: all linear .3s;
//-webkit-animation-duration:1s;
//-webkit-animation-delay: 2s;
//-webkit-animation-iteration-count: infinite;
}
